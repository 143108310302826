
.project-card{
    background-color: rgb(47, 42, 42);
    background-color: rgb(2, 23, 65);
    color:white;
    line-height: 1.4;
    gap: 2px;
    padding: 15px;
    width: 200px;
    height: 50px;
    margin-bottom: 3px;
    border-radius: .3rem;
    cursor: pointer;
  }
  
  .project-card:hover{
    color: red;
  }

  .project-img {
    height: 250px;
    width: 500px;
    padding: 10px;
  }

  .project-img-2nd{
    height: 250px;
    width: 500px;
    padding: 10px;
  }

  .project-mobile-img {
    height: 320px;
    width: 170px;
    width: 160px;
    margin-left: 15px;
    margin-right: 5px;
  }

  .project-img:hover{
    height: 350px;
    width: 600px;
  } 

  .project-mobile-img:hover{
    height: 370px;
    width: 210px;
  } 