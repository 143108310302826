.App {
  background-color: steelblue;
  color: black;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  /* font-family: 'Times New Roman', Times, serif;
  font-family: 'Open Sans', sans-serif;
  font-family: 'Helvetica Neue';
  font-family: 'Lato';
  font-family: 'Arial'; */

  font-family: BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, 
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  font-size: 15px;

  display: grid;
  grid-template-areas:
    'header header'
    'sidebar content';
  grid-template-columns: 1.9fr 6fr;
  overflow: hidden;

}

header {
  grid-area: header;
}

content {
  grid-area: content;
  min-height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}

.page-content {
  /* background-color: #ECE9F0; */
  background-color: #f0ecf2;
  padding-left: 20px;
}

.page-content-text {
  max-width: 900px;
  line-height: 1.3;
  padding-bottom: 5px;
}

.App-header {
  background-color: steelblue;
  cursor: default;

  font-size: 20px;
  color: white;

  display: grid;
  grid-template-rows: 42px;
  justify-self: right;
  align-items: center;
}

.sidebar {
  height: 100vh;
  width: 100vw;

  background-color: white;
  padding: 45px;

  display: grid;
  grid-template-rows: repeat(10, 40px);
  gap: 15px;
  align-items: center;
}

.sidebar-button {

  background-color: steelblue;
  color: white;
  width: 280px;
  font-size: 20px;
  padding: 10px;

  display: grid;
  text-align-last: center;
  cursor: pointer;
  text-decoration: none;
  border-radius: .5rem
}

.sidebar-button:hover {
  color: rgb(0, 255, 85);
}

h2 {
  color: red;
}

.things {
  display: grid;
  grid-template-columns: 1fr 3fr;
  background-color: #f0ecf2;

}

.left {
  width: 800px;
  height: 700px;
  padding: 10px;
  cursor: default;

  overflow: hidden;
  overflow-y: scroll;
}

.right {
  width: auto;
  margin-top: 55px;
  margin-left: 20px;
}

.right-sidebar-button {
  background-color: lightgrey;
  line-height: 1.4;
  gap: 2px;
  padding: 15px;
  width: 296px;
  margin-bottom: 3px;
  border-radius: .3rem;
  cursor: pointer;
  font-size: 16px;
}

.right-sidebar-button:hover{
  color: red;
}

.code-snippet {
  margin: 6px;
  margin-bottom: 10px;
  
  padding: 2px;
  padding-left: 10px;
  width: 700px;
  width: auto;
  background-color: lightgrey;
  background-color: rgb(212, 208, 208);
  color: black;
  font-size: 15px;
}

.sub-page {
  margin-top: 20px;
  height: auto;
  width: 800px;
  padding-left: 10px;

  overflow: hidden;
  overflow-y: scroll;

  border-radius: .5rem;
  /* font-size: 16px; */
}


.home-alien-img {
  width: 250px;
  height: 300px;
}

.home-img {
  /* width: 250px;
  height: 450px;
  padding: 8px; */

  width: 250px;
  height: 420px;
  padding: 8px; 
}

.smalien-img {
  width: 50px;
  height: 60px;
}

.sub-page-image {
  padding: 15px;
  padding-left: 20px;
  width: 450px;
  height: 300px;
}

.project-folder-img {
  padding: 10px;
}
