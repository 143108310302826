.sliding-window {
    position: fixed; /* Ensures the page is positioned relative to the viewport */
    top: 0;
    left: -100%; /* Start off the screen to the left */
    width: 100%; /* Full width */
    height: 100%; /* Full height */

    width: 55%; /* Full width */
    height: 80%; /* Full height */
    
    background-color: rgb(95, 161, 153); /* Page background color */
    transition: left 0.5s ease-in-out; /* Smooth sliding transition */
    z-index: 100; /* Stack on top of other content */

    border: 4px solid white; /* Proper border declaration */
    margin-top: 20px;
    margin-left: 2px;
    padding-left: 2px;

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    text-align: left;
    padding-top: 0px;
    font-size: 24px;
    color: white;

    overflow: hidden; /* Ensure content doesn't overflow the main container */
    overflow-y: auto; /* Allow vertical scrolling */
  }
  
  .sliding-window.visible {
    left: 0; /* Slide into view */
  }

  .sliding-window-button:hover {
    cursor: pointer;
  }
  
  .sliding-window-button {
    display: flex;
    justify-content: center;
    align-items: center;
  
    height: 40px;
    width: 100px;
  
    margin-left: 86%;
    margin-top: 6px;
    margin-bottom: 2px;
  
    font-size: 18px;
    cursor: pointer;
    border: none;
    border-radius: .3em;
  
    background-color: black;
    color: white;
  }