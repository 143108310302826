.header-button{
    padding: 1px;
    margin-right: 10px;
    /* box-shadow: 0 0 1em rgba(127, 174, 239, 0.9); */
    background-color: rgb(36, 93, 139);
    background-color: black;
    width: 70px;
    border-radius: .3em;
    text-align: center;
    cursor: pointer;
    color: silver;
}

/* .header-button:hover{
    width: 80px;
} */