/* Top Section bullet points */
ul {
    padding-left: 0px;
    margin-left: 18px;
}

.property-container {
    display: grid;
    grid-template-columns: repeat(4, 200px);
    padding-top: 20px;
}

/* Bottom section Dash image + node image  */

.dash-img {
    grid-area: 'dash-img';
    width: 190px;
    height: 350px;
    margin-left: 1px;
}

.detail-img {
    grid-area: 'dash-img';
    width: 410px;
    height: 350px;
    margin-left: 20px;
}

.dash-img:hover {
    transform: scale(1.1);
} 

.help{
    font-weight: bold;
    color: steelblue
}